<!-- ValueChainListing.vue -->
<template>
  <div class="container-gallery">
    <div class="header">
      <div class="header-title">
        {{ title }}
      </div>
      <div
        v-if="insertButton"
        @click="goToAddNewValueChainPage"
        style="margin-right: -8px"
      >
        <ellipse-icon
          class="button_card_icon"
          :width="`45px`"
          :height="`45px`"
          style="cursor: pointer"
        >
          <v-icon style="color: #582963" large>add</v-icon>
        </ellipse-icon>
      </div>
    </div>

    <div v-for="item in items" :key="item.id">
      <value-chain-item
        :item="item"
        :isDeletable="isDeletable"
        @delete="deleteValueChain"
        @edit="openEditValueChain(item)"
      />
    </div>
  </div>
</template>

<script>
import routes from "../../router/routes";
import ValueChainItem from "../ValueChainItem.vue";

export default {
  name: "ValueChainListing",
  components: {
    ValueChainItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    insertButton: {
      type: Boolean,
      default: false,
    },
    isDeletable: {
      type: [Boolean, Number],
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToAddNewValueChainPage() {
      this.$router.push({ name: routes.ADD_VALUE_CHAIN });
    },
    deleteValueChain(id) {
      this.$emit("delete", id);
    },
    openEditValueChain(item) {
      this.$router.push({
        name: routes.ADD_VALUE_CHAIN,
        params: { valueChainId: item.id, valueChainData: item },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-gallery {
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-title {
  font-size: 18px;
  font-weight: bold;
  color: #473068;
}
</style>
