<template>
  <div :class="`${variant}-tile`">
    <div class="tile-container">
      <div class="tile-title" :style="`${amount ? 'min-height: 0px' : ''}`">
        {{ title }}
      </div>
      <div
        v-if="!loading"
        class="tile-content"
        :style="`${amount ? 'margin-top: 5px' : ''}`"
      >
        <div class="tile-count">{{ count }}</div>
        <div v-if="amount" class="tile-count">{{ amount }}</div>
        <div
          v-if="percentage !== null"
          :class="`tile-percentage ${
            percentage > 0
              ? 'percentage-positive'
              : percentage < 0
              ? 'percentage-negative'
              : 'percentage-zero'
          }`"
        >
          {{ Math.abs(percentage.toFixed(percentageDecimals)) }}
        </div>
        <div v-else class="no-tile-percentage">N/A</div>
      </div>
      <div v-else class="tile-loading">
        <md-progress-spinner
          class="tile-loading-spinner"
          md-mode="indeterminate"
          :md-stroke="2"
          :md-diameter="30"
        ></md-progress-spinner>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnalyticsTile",
  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    amount: {
      type: String,
      default: null,
    },
    percentage: {
      type: Number,
      default: null,
    },
    percentageDecimals: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "info",
    },
  },
};
</script>

<style lang="scss" scoped>
.tile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 220px;
  min-height: 170px;
}
.tile-loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tile-title {
  font-size: 16px;
  font-weight: bold;
  min-height: 48px;
}
.tile-content {
  margin-top: 10px;
}
.tile-count {
  font-size: 22px;
  font-weight: bolder;
}
.tile-percentage {
  font-size: 18px;
  font-weight: bolder;
  display: inline-flex;
}
.no-tile-percentage {
  font-size: 18px;
  font-weight: bolder;
  color: #6a7895;
}
.percentage-positive {
  color: #1be153;
}
.tile-percentage::after {
  content: "%";
}
.percentage-positive::before {
  content: "+";
}
.percentage-negative::before {
  content: "-";
}
.percentage-negative {
  color: #ff0000;
}
.percentage-zero {
  color: #ea7a66;
}
.info-tile {
  .tile-container {
    background-color: #eef1fa;
  }
  .tile-title {
    color: #473068;
  }
  .tile-count {
    color: black;
  }
  .tile-loading-spinner::v-deep .md-progress-spinner-circle {
    stroke: #492a67 !important;
  }
}
.primary-tile {
  .tile-container {
    background-color: #5b3d87;
  }
  .tile-title {
    color: white;
  }
  .tile-count {
    color: white;
  }
  .tile-loading-spinner::v-deep .md-progress-spinner-circle {
    stroke: white !important;
  }
}
</style>
