<template>
  <div class="listing-container">
    <div class="listing-title">{{ title }}</div>
    <div v-if="!loading" class="items-container">
      <div v-if="items.length > 0" style="width: 100%">
        <div
          :class="`item-container ${item.navigation ? 'item-navigation' : ''}`"
          v-for="(item, index) in items"
          :key="item.id || index"
          @click.stop="item.navigation ? onClickItem(item.navigation) : null"
        >
          <div class="positions-container">
            <div class="item-position">{{ item.position }}</div>
            <div v-if="item.up">
              <v-icon class="position-up">mdi-triangle-small-up</v-icon>
            </div>
            <div v-else-if="item.down">
              <v-icon class="position-down">mdi-triangle-small-down</v-icon>
            </div>
            <div v-else-if="item.same">
              <v-icon class="position-same">mdi-circle-small</v-icon>
            </div>
            <div>
              <v-icon v-if="!item.avatar" size="40" color="#473068"
                >mdi-domain</v-icon
              >
              <v-avatar v-else size="40">
                <v-img :src="item.avatar" />
              </v-avatar>
            </div>
          </div>
          <div class="details-container">
            <div class="item-name">{{ item.name }}</div>
            <div
              class="item-data"
              v-for="(data, subIndex) in item.details || []"
              :key="subIndex"
            >
              <div>{{ data }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-data-message">No data available</div>
    </div>
    <div v-else class="loading-container">
      <md-progress-spinner
        class="listing-loading-spinner"
        md-mode="indeterminate"
        :md-stroke="2"
        :md-diameter="30"
      ></md-progress-spinner>
    </div>
    <div v-if="totalCount > perPageCount" class="listing-pagination-container">
      <pagination
        :current-page="currentPage"
        :total-count="totalCount"
        :per-page-count="perPageCount"
        :max-visible-pages="maxVisiblePages"
        @load-page="loadPage"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "./Pagination.vue";

export default {
  name: "AnalyticsListing",
  components: {
    Pagination,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    perPageCount: {
      type: Number,
      default: 10,
    },
    maxVisiblePages: {
      type: Number,
      default: 5,
    },
  },
  methods: {
    loadPage(page) {
      this.$emit("load-page", page);
    },
    onClickItem(navigation) {
      const newTab = window.open(navigation, "_blank");
      newTab.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.listing-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: calc(50% - 20px);
  min-height: 170px;
  background-color: #eef1fa;
  position: relative;
}
.items-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  min-height: 348px;
  width: 100%;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 348px;
  width: 100%;
  margin-top: 15px;
}
.no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #828282;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 20px;
  gap: 15px;
  width: 100%;
}
.item-navigation {
  cursor: pointer;
}
.item-navigation:hover {
  background-color: #d6d9f0;
  border-radius: 10px;
}
.positions-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.listing-title {
  font-size: 16px;
  font-weight: bolder;
  color: #473068;
  margin: 20px 20px 10px 20px;
}
.item-position {
  font-size: 16px;
  font-weight: bold;
  color: #151e23;
}
.item-name {
  font-size: 16px;
  font-weight: bold;
  color: #473068;
  text-align: left;
  word-wrap: anywhere;
}
.item-data {
  @extend .item-name;
  font-weight: 500;
  color: #828282;
}
.item-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.position-up {
  color: #1be153 !important;
  font-size: 32px;
  width: 20px;
}
.position-down {
  @extend .position-up;
  color: #ff0000 !important;
}
.position-same {
  @extend .position-up;
  font-size: 38px;
  color: #828282 !important;
}
.listing-pagination-container {
  flex: 1;
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  padding: 0px 15px 5px 0px;
}
.listing-loading-spinner::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}
</style>
