var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${_vm.variant}-tile`},[_c('div',{staticClass:"tile-container"},[_c('div',{staticClass:"tile-title",style:(`${_vm.amount ? 'min-height: 0px' : ''}`)},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!_vm.loading)?_c('div',{staticClass:"tile-content",style:(`${_vm.amount ? 'margin-top: 5px' : ''}`)},[_c('div',{staticClass:"tile-count"},[_vm._v(_vm._s(_vm.count))]),(_vm.amount)?_c('div',{staticClass:"tile-count"},[_vm._v(_vm._s(_vm.amount))]):_vm._e(),(_vm.percentage !== null)?_c('div',{class:`tile-percentage ${
          _vm.percentage > 0
            ? 'percentage-positive'
            : _vm.percentage < 0
            ? 'percentage-negative'
            : 'percentage-zero'
        }`},[_vm._v(" "+_vm._s(Math.abs(_vm.percentage.toFixed(_vm.percentageDecimals)))+" ")]):_c('div',{staticClass:"no-tile-percentage"},[_vm._v("N/A")])]):_c('div',{staticClass:"tile-loading"},[_c('md-progress-spinner',{staticClass:"tile-loading-spinner",attrs:{"md-mode":"indeterminate","md-stroke":2,"md-diameter":30}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }