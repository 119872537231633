<template>
  <div class="company-profile">
    <div
      class="cover-photo"
      :style="{
        backgroundImage: cover
          ? `url(${cover})`
          : 'linear-gradient(to bottom right, #ccc, #473068)',
      }"
    >
      <div v-if="displayLabel" class="verified-label-container">
        <div v-if="isVerified" class="verified-label">
          <v-icon class="label-icon">mdi-check-circle</v-icon>
          Verified
        </div>
        <div v-else class="unverified-label">
          <v-icon class="label-icon">mdi-alert-circle</v-icon>
          Unverified
        </div>
      </div>
      <input
        ref="coverInput"
        type="file"
        @change="handleCoverUpdate"
        accept="image/*"
        style="display: none"
      />
      <div v-if="edit" class="edit-button" @click="openCoverFileSelector">
        <v-icon dark>mdi-camera-party-mode</v-icon>
      </div>
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
      "
    >
      <div class="profile-picture-container" style="margin-left: 0">
        <div class="profile-picture">
          <img :src="displayLogo" alt="Company Logo" />
          <input
            ref="logoInput"
            type="file"
            @change="handleLogoUpdate"
            accept="image/*"
            style="display: none"
          />
          <div v-if="edit" class="edit-button" @click="openLogoFileSelector">
            <v-icon dark>mdi-camera-party-mode</v-icon>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding: 10px 20px;
        "
      >
        <slot name="buttons" />
      </div>
    </div>

    <div class="content">
      <h2>{{ name }}</h2>
      <p class="account-type-text">Account Type: {{ type }}</p>
      <p class="account-location-text">{{ location }}</p>

      <!-- <div v-if="valueChainPartners.length > 0" class="value-chain">
        <div class="partner-list">
          <div
            v-for="(partner, index) in valueChainPartners.slice(0, 3)"
            :key="index"
            class="partner"
          >
            <img :src="partner.url || defaultImage" alt="Partner Logo" />
          </div>
          <span v-if="valueChainPartners.length > 3" class="more-partners">
            and {{ valueChainPartners.length - 3 }} other value chain partners.
          </span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import defaultImage from "@/assets/company.png";

export default {
  name: "CompanyProfile",
  props: {
    logo: {
      type: String,
      default: null,
    },
    cover: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    location: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      required: true,
    },
    displayLabel: {
      type: Boolean,
      default: false,
    },
    isVerified: {
      type: [Boolean, Number],
      default: false,
    },
    valueChainPartners: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    displayLogo() {
      return this.logo || defaultImage;
    },
  },
  methods: {
    openCoverFileSelector() {
      if (this.isVerified) {
        alert(
          "Changing the cover will cause your profile to become unverified and it will affect all transactions associated with this profile in published Digital Product Passports.\n\nPlease request for verification again after you have made the change."
        );
      }
      this.$refs.coverInput.click();
    },
    openLogoFileSelector() {
      if (this.isVerified) {
        alert(
          "Changing the logo will cause your profile to become unverified and it will affect all transactions associated with this profile in published Digital Product Passports.\n\nPlease request for verification again after you have made the change."
        );
      }
      this.$refs.logoInput.click();
    },
    handleCoverUpdate(event) {
      const file = event.target.files[0];
      if (file) {
        this.$emit("change-cover", file);
      }
    },
    handleLogoUpdate(event) {
      const file = event.target.files[0];
      if (file) {
        this.$emit("change-logo", file);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company-profile {
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

.cover-photo {
  width: 100%;
  height: calc(16vw);
  background-size: cover;
  background-position: center;
  position: relative;
}

.edit-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 10px;
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  outline: 2px solid #fff;
  cursor: pointer;
  font-size: 18px;
  z-index: 1;

  .v-icon {
    font-size: 25px;
  }
}

.profile-picture {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.profile-picture-container {
  width: 15vw;
  height: 15vw;
  margin-top: -7.5vw;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: 5px solid #fff;
  overflow: hidden;
  margin-left: 4vw !important;
  background-color: #ffffff;
}

@media only screen and (max-width: 756px) {
  .cover-photo {
    height: calc(30vw);
  }

  .profile-picture-container {
    height: 25vw;
    width: 25vw;
    margin-top: -12.5vw;
  }

  .edit-button {
    width: 25px;
    height: 25px;
    bottom: 5px;
    right: 5px;

    .v-icon {
      font-size: 18px;
    }
  }
}

.account-location-text {
  color: #473068;
  font-size: 16px;
  opacity: 0.7;
}

.value-chain {
  width: 100%;
  margin-top: 20px;

  h3 {
    font-size: 20px;
    color: #473068;
    margin-bottom: 10px;
    opacity: 70%;
  }
}

.more-partners {
  color: #555;
  font-size: 14px;
}

.partner-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.partner {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    object-fit: cover;
  }
}
.content {
  width: 100%;
  font-family: sans-serif !important;
  padding: 20px;
  text-align: left;
  margin-left: calc(4vw - 20px) !important;

  h2 {
    color: #473068;
    font-size: 28px;
    margin-bottom: 10px;
    line-height: 30px;
  }
}

.account-type-text {
  color: #473068;
  font-size: 16px;
  margin-bottom: 5px;
  opacity: 0.7;
  font-weight: bold;
}

.verified-label-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.label-icon {
  color: #fff;
  margin-right: 10px;
  font-size: 16px;
}

.verified-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4caf50;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.unverified-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f44336;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
