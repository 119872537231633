<template>
  <div class="container-gallery">
    <div class="header">
      <div class="header-title">
        {{ title }}
      </div>
      <div v-if="insertButton" @click="insertItem" style="margin-right: -8px">
        <ellipse-icon
          class="button_card_icon"
          :width="`45px`"
          :height="`45px`"
          style="cursor: pointer"
        >
          <v-icon style="color: #582963" large>add</v-icon>
        </ellipse-icon>
      </div>
    </div>

    <div v-if="items && items.length" class="items-container">
      <div
        v-for="(item, index) in items"
        :class="`flexbox-item ${item.onClick ? 'clickable' : ''}`"
        :key="item.id"
        :style="index % 5 === 0 ? 'margin-left: 0px;' : ''"
        @click="item.onClick(item)"
      >
        <div class="verify-icon-container" v-if="item.verified">
          <v-tooltip color="#6A7895" bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="verify-icon-container">
                <verify-icon width="15pt" height="15pt"></verify-icon>
              </div>
            </template>
            <div class="hover-container">
              <span>Verified</span>
            </div>
          </v-tooltip>
        </div>
        <div class="item-file">
          <embed
            class="item-file-preview"
            v-if="item.url && isSupportedFileType(item.url)"
            :src="item.url"
          />
          <div class="item-file-no-preview" v-else>No preview available.</div>
          <div v-if="item.seal" class="seal-container">
            <div class="seal-text">{{ item.seal }}</div>
          </div>
        </div>
        <div class="item-title">
          {{ item.title }}
        </div>
        <div class="item-description">
          {{ item.description }}
        </div>
        <div class="options-container" v-if="itemOptions?.length">
          <div class="options-button" @click.stop="onClickShowOptions(item.id)">
            <v-icon>more_vert</v-icon>
          </div>
          <div
            v-if="showOptions && showOptions === item.id"
            class="options-dropdown"
          >
            <div
              v-for="(option, index) in itemOptions"
              :key="index"
              @click.stop="
                showOptions = false;
                option.onClick(item);
              "
              class="options-dropdown-item"
              :style="`color: ${option.color};`"
            >
              {{ option.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-items-container">No items to display.</div>
  </div>
</template>
<script>
export default {
  name: "OrganizationGalleryListing",
  props: {
    title: {
      type: String,
      required: true,
    },
    insertButton: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemOptions: {
      type: Array,
      default: () => [],
    },
    isVerified: {
      type: [Boolean, Number],
      default: false,
    },
  },
  data: () => ({
    showOptions: false,
    form: {
      file: null,
      title: "",
      description: "",
    },
    rules: {
      titleRules: [
        (v) => !!v || "Title is required",
        (v) =>
          (v && v.length <= 255) || "Title must be less than 255 characters",
      ],
      descriptionRules: [
        (v) => !!v || "Description is required",
        (v) =>
          (v && v.length <= 255) ||
          "Description must be less than 255 characters",
      ],
    },
  }),
  methods: {
    insertItem() {
      if (this.isVerified) {
        alert(
          "Editing these details will cause your profile to become unverified and it will affect all transactions associated with this profile in published Digital Product Passports.\n\nPlease request for verification again after you have edited the details."
        );
      }
      this.$emit("insert-item");
    },
    isSupportedFileType(url) {
      return (
        url.match(
          /\.(jpeg|jpg|gif|png|pdf|txt|swf|svg|xhtml|html|htm|mp3|wav|ogg|mp4|webm|webp)$/
        ) != null
      );
    },
    onClickShowOptions(itemId) {
      this.showOptions = this.showOptions === itemId ? false : itemId;
    },
  },
};
</script>
<style scoped>
.container-gallery {
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-title {
  font-size: 18px;
  font-weight: bold;
  color: #473068;
}
.items-container {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.no-items-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 100;
  color: #473068;
  background-color: #faf7ff;
}
.clickable {
  cursor: pointer;
}
.verify-icon-container {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  top: 4px;
  right: 4px;
  z-index: 1;
}
.flexbox-item {
  position: relative;
  margin-bottom: 20px;
  margin-left: 20px;
  width: calc((100% - 80px) / 5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media only screen and (max-width: 756px) {
  .flexbox-item {
    margin-left: 0px;
    width: 100%;
  }
}
.flexbox-item .item-file {
  width: 100%;
  height: 30vw;
  max-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #473068;
  border-radius: 5px;
}
.flexbox-item .item-file-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.flexbox-item .item-file-no-preview {
  color: #473068;
  font-size: 14px;
}
.flexbox-item .item-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #473068;
  overflow-wrap: anywhere;
  text-align: left;
}
.flexbox-item .item-description {
  font-size: 14px;
  color: #473068;
  overflow-wrap: anywhere;
  text-align: left;
}
.options-container {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.options-button {
  background-color: white;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  cursor: pointer;
}
.options-dropdown {
  top: 20px;
  right: 0;
  background-color: white;
  border: 1px solid #473068;
  border-radius: 5px;
  padding: 5px;
  z-index: 1;
  text-align: left;
  padding: 10px;
}
.options-dropdown-item {
  padding: 5px;
  cursor: pointer;
}
.seal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 82, 82, 0.5);
  height: 30vw;
  max-height: 150px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}
.seal-text {
  background-color: #ff5252;
  color: white;
  padding: 10px;
  width: 100%;
}
</style>
