<template>
  <div>
    <v-tooltip
      v-if="
        !$store.state.isCityAndCountryFilled ||
        $store.state.accountVerificationRequested
      "
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <ui-button
            class="primary long"
            :disabled="true"
            @click="onRequestVerification"
            :title="'Request Verification'"
          />
        </div>
      </template>
      <div class="hover-container">
        <span v-if="!$store.state.isCityAndCountryFilled">
          Please fill city and country in the company profile first
        </span>
        <span v-else-if="$store.state.accountVerificationRequested">
          Verification request already sent. Please wait for admin approval.
        </span>
      </div>
    </v-tooltip>
    <ui-button
      v-else
      class="primary long"
      :disabled="false"
      @click="onRequestVerification"
      :title="'Request Verification'"
    />
  </div>
</template>

<script>
export default {
  name: "RequestVerificationButton",
  methods: {
    onRequestVerification() {
      this.$emit("request-verification");
    },
  },
};
</script>

<style lang="scss" scoped>
.hover-container {
  max-width: 200px;
  word-wrap: anywhere;
}
</style>
