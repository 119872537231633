<template>
  <v-app>
    <v-container
      fluid
      tag="section"
      style="background-color: #eef1fa; min-height: 100vh"
    >
      <company-profile
        :edit="!isPublicPage && isEditing"
        :displayLabel="!isPublicPage"
        :name="organizationDetails.name"
        :type="organizationDetails.type"
        :location="
          accountDetails.city && accountDetails.country
            ? `${accountDetails.city}, ${accountDetails.country}`
            : ''
        "
        :isVerified="isVerified"
        :logo="accountDetails.logo"
        :cover="accountDetails.cover"
        @change-logo="updateLogo"
        @change-cover="updateCover"
      >
        <template v-slot:buttons>
          <div style="display: flex; margin-right: -8px">
            <ui-button
              v-if="!isPublicPage && isVerified && !isEditing"
              class="primary long"
              @click="editProfile"
              :title="'Edit Profile'"
            />
            <div v-if="!isPublicPage && !isVerified">
              <request-verification-button
                @request-verification="
                  requestVerificationForAccount(organizationDetails.id)
                "
              />
            </div>
            <ui-button
              v-if="!isPublicPage"
              class="primary long"
              @click="toggleAccountPrivateStatus"
              :title="isAccountPrivate ? 'Make Public' : 'Make Private'"
            />
            <ui-button class="primary long" title="Share" @click="copyLink" />
          </div>
        </template>
      </company-profile>

      <v-tabs
        v-if="accountUuid || isSuperAdmin"
        v-model="activeTab"
        color="#442d64"
        slider-color="#70D0CE"
        show-arrows
        grow
      >
        <v-tab v-if="accountUuid" href="#home"
          ><span
            :class="`tab-text ${activeTab === 'home' ? 'tab-text-active' : ''}`"
            >Home</span
          ></v-tab
        >
        <v-tab v-if="accountUuid" href="#about"
          ><span
            :class="`tab-text ${
              activeTab === 'about' ? 'tab-text-active' : ''
            }`"
            >About</span
          ></v-tab
        >
        <v-tab v-if="accountUuid" href="#teamMembers"
          ><span
            :class="`tab-text ${
              activeTab === 'teamMembers' ? 'tab-text-active' : ''
            }`"
            >Team Members</span
          ></v-tab
        >
        <v-tab v-if="accountUuid || isSuperAdmin" href="#factories"
          ><span
            :class="`tab-text ${
              activeTab === 'factories' ? 'tab-text-active' : ''
            }`"
            >{{ isBrandPage ? "Stores" : "Factories" }}</span
          ></v-tab
        >
        <v-tab v-if="accountUuid" href="#products"
          ><span
            :class="`tab-text ${
              activeTab === 'products' ? 'tab-text-active' : ''
            }`"
            >Products</span
          ></v-tab
        >
        <v-tab v-if="accountUuid" href="#valueChainPartners"
          ><span
            :class="`tab-text ${
              activeTab === 'valueChainPartners' ? 'tab-text-active' : ''
            }`"
            >Value Chain Partners</span
          ></v-tab
        >
        <v-tab v-if="accountUuid" href="#certifications"
          ><span
            :class="`tab-text ${
              activeTab === 'certifications' ? 'tab-text-active' : ''
            }`"
            >Certifications</span
          ></v-tab
        >
        <v-tab v-if="isSuperAdmin || isOwner" href="#analytics"
          ><span
            :class="`tab-text ${
              activeTab === 'analytics' ? 'tab-text-active' : ''
            }`"
            >Analytics</span
          ></v-tab
        >
      </v-tabs>

      <v-tabs-items
        v-if="accountUuid || isSuperAdmin"
        style="margin-top: 10px; background-color: transparent"
        v-model="activeTab"
      >
        <v-tab-item value="home">
          <div>
            <about
              :expanded="aboutExpanded"
              :expandable="true"
              :editable="!isPublicPage && isEditing"
              :isVerified="isVerified"
              :name="organizationDetails.name"
              :about="accountDetails.about"
              :website="accountDetails.website"
              :registrationNumber="accountDetails.registrationNumber"
              :establishedCity="accountDetails.establishedCity"
              :establishedYear="accountDetails.establishedYear"
              :vision="accountDetails.vision"
              :mission="accountDetails.mission"
              :email="accountDetails.contactEmail"
              :mobile="accountDetails.contactMobile"
              :addressLine1="accountDetails.addressLine1"
              :addressLine2="accountDetails.addressLine2"
              :city="accountDetails.city"
              :state="accountDetails.state"
              :country="accountDetails.country"
              :postalCode="accountDetails.postalCode"
              @expand="toggleAboutExpanded"
              @save="updateAccount"
            />
          </div>
          <div
            v-if="!isPublicPage || (teamMembers && teamMembers.length)"
            style="margin-top: 10px"
          >
            <organization-gallery-listing
              title="Team Members"
              :insertButton="!isEditing || isPublicPage ? false : true"
              :items="teamMembers"
              @insert-item="showAddNewMemberForm = true"
              :itemOptions="
                !isEditing || isPublicPage
                  ? []
                  : [
                      {
                        text: 'Delete',
                        color: '#EA7A66',
                        onClick: (item) => deleteTeamMember(item.id),
                      },
                    ]
              "
            />
          </div>
          <div
            v-if="!isPublicPage || (factories && factories.length)"
            style="margin-top: 10px"
          >
            <organization-gallery-listing
              :title="isBrandPage ? 'Stores' : 'Factories'"
              :items="factories"
              :isVerified="isSuperAdmin ? false : isVerified"
              :insertButton="
                isSuperAdmin ? true : !isEditing || isPublicPage ? false : true
              "
              :itemOptions="
                !isEditing || isPublicPage
                  ? []
                  : [
                      {
                        text: 'Edit',
                        color: '#442D65',
                        onClick: (item) => navigateToFactory(item.id),
                      },
                      {
                        text: 'Delete',
                        color: '#EA7A66',
                        onClick: (item) => deleteFactory(item.id),
                      },
                    ]
              "
              @insert-item="showAddNewFactoryForm = true"
            />
          </div>
          <div
            v-if="!isPublicPage || (productImages && productImages.length)"
            style="margin-top: 10px"
          >
            <organization-gallery-listing
              title="Products"
              :items="productImages"
              :insertButton="!isEditing || isPublicPage ? false : true"
              :itemOptions="
                !isEditing || isPublicPage
                  ? []
                  : [
                      {
                        text: 'Delete',
                        color: '#EA7A66',
                        onClick: (item) => deleteProduct(item.id),
                      },
                    ]
              "
              @insert-item="showAddNewProductForm = true"
            />
          </div>
          <div
            v-if="
              !isPublicPage || (valueChainPartners && valueChainPartners.length)
            "
            style="margin-top: 10px"
          >
            <organization-gallery-listing
              title="Value Chain Partners"
              :items="valueChainPartners"
            />
          </div>
          <div
            v-if="!isPublicPage || (certifications && certifications.length)"
            style="margin-top: 10px"
          >
            <organization-gallery-listing
              title="Certifications"
              :items="certifications"
              :isVerified="isVerified"
              :insertButton="!isEditing || isPublicPage ? false : true"
              :itemOptions="
                !isEditing || isPublicPage
                  ? [
                      {
                        text: 'Download',
                        color: '#442D65',
                        onClick: (item) =>
                          downloadCertification(item.downloadId),
                      },
                    ]
                  : [
                      {
                        text: 'Download',
                        color: '#442D65',
                        onClick: (item) =>
                          downloadCertification(item.downloadId),
                      },
                      {
                        text: 'Delete',
                        color: '#EA7A66',
                        onClick: (item) => deleteCertification(item.id),
                      },
                    ]
              "
              @insert-item="showAddNewCertificationForm = true"
            />
          </div>
        </v-tab-item>
        <v-tab-item value="about">
          <about
            :expandable="false"
            :expanded="true"
            :editable="!isPublicPage && isEditing"
            :name="organizationDetails.name"
            :about="accountDetails.about"
            :website="accountDetails.website"
            :registrationNumber="accountDetails.registrationNumber"
            :establishedCity="accountDetails.establishedCity"
            :establishedYear="accountDetails.establishedYear"
            :vision="accountDetails.vision"
            :mission="accountDetails.mission"
            :email="accountDetails.contactEmail"
            :mobile="accountDetails.contactMobile"
            :addressLine1="accountDetails.addressLine1"
            :addressLine2="accountDetails.addressLine2"
            :city="accountDetails.city"
            :state="accountDetails.state"
            :country="accountDetails.country"
            :postalCode="accountDetails.postalCode"
            @save="updateAccount"
          />
        </v-tab-item>
        <v-tab-item value="teamMembers">
          <organization-gallery-listing
            title="Team Members"
            :items="teamMembers"
            :insertButton="!isEditing || isPublicPage ? false : true"
            :itemOptions="
              isPublicPage
                ? []
                : [
                    {
                      text: 'Delete',
                      color: '#EA7A66',
                      onClick: (item) => deleteTeamMember(item.id),
                    },
                  ]
            "
            @insert-item="showAddNewMemberForm = true"
          />
        </v-tab-item>
        <v-tab-item value="factories">
          <organization-gallery-listing
            :title="isBrandPage ? 'Stores' : 'Factories'"
            :items="factories"
            :isVerified="isSuperAdmin ? false : isVerified"
            :insertButton="
              isSuperAdmin ? true : !isEditing || isPublicPage ? false : true
            "
            :itemOptions="
              isPublicPage
                ? []
                : [
                    {
                      text: 'Edit',
                      color: '#442D65',
                      onClick: (item) => navigateToFactory(item.id),
                    },
                  ]
            "
            @insert-item="showAddNewFactoryForm = true"
          />
        </v-tab-item>
        <v-tab-item value="products">
          <organization-gallery-listing
            title="Products"
            :items="productImages"
            :insertButton="!isEditing || isPublicPage ? false : true"
            :itemOptions="
              isPublicPage
                ? []
                : [
                    {
                      text: 'Delete',
                      color: '#EA7A66',
                      onClick: (item) => deleteProduct(item.id),
                    },
                  ]
            "
            @insert-item="showAddNewProductForm = true"
          />
        </v-tab-item>

        <v-tab-item v-if="isSuperAdmin || isOwner" value="analytics">
          <div style="background-color: white; padding: 20px">
            <div class="filter-row">
              <v-select
                v-model="selectedDateRange"
                :items="dateRangeOptions"
                :label="dateRangeLabel"
                class="filter-select"
                outlined
                dense
                hide-details
                @mousedown.stop.prevent="openDatePicker"
                @click="openDatePicker"
                @click:append="openDatePicker"
              ></v-select>
            </div>
            <div class="tiles-container">
              <div
                v-for="tile in tilesArray"
                :key="tile.title"
                class="tile-item"
              >
                <analytics-tile
                  :title="tile.title"
                  :count="tile.count"
                  :percentage="tile.percentage"
                  :loading="productTilesLoading"
                />
              </div>
            </div>
            <div class="listings-container">
              <analytics-listing
                v-for="listing in listingArray"
                :key="listing.title"
                :title="listing.title"
                :loading="listing.loading"
                :items="listing.data"
                :totalCount="listing.total"
                :currentPage="listing.page"
                :perPageCount="4"
                @load-page="loadListingPage(listing.loadPage, ...arguments)"
              />
            </div>
            <v-dialog
              v-model="dateMenuVisible"
              max-width="500px"
              persistent
              scrollable
              @click:outside="closeDatePicker"
            >
              <v-card class="centered-card">
                <v-card-title>Select timeframe</v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-tabs
                        vertical
                        v-model="selectedTab"
                        class="tabs-column"
                      >
                        <v-tab
                          v-for="option in tabOptions"
                          :key="option"
                          @click="selectTab(option)"
                          class="tab-item"
                        >
                          {{ option }}
                        </v-tab>
                      </v-tabs>
                    </v-col>

                    <v-col cols="1">
                      <v-divider vertical class="divider"></v-divider>
                    </v-col>

                    <v-col cols="7">
                      <v-text-field
                        v-model="dateRange.from"
                        label="Start"
                        type="date"
                        class="date-input"
                        outlined
                        :max="dateRange.to || null"
                        :disabled="selectedTab !== 0"
                        @change="selectTab()"
                      ></v-text-field>
                      <v-text-field
                        v-model="dateRange.to"
                        label="End"
                        type="date"
                        class="date-input"
                        outlined
                        :min="dateRange.from || null"
                        :disabled="selectedTab !== 0"
                        @change="selectTab()"
                      ></v-text-field>
                      <v-alert
                        v-if="!isDateRangeValid"
                        type="error"
                        border="left"
                        class="mt-2"
                      >
                        The "To" date must be later than the "From" date.
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="closeDatePicker"
                    :disabled="!isDateRangeValid"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-tab-item>

        <!-- ----------------------------------- Value Chain Partners ------------------------------------ -->
        <v-tab-item value="valueChainPartners">
          <value-chain-listing
            title="Value Chains"
            :insertButton="!isPublicPage"
            :isDeletable="isEditing && !isPublicPage"
            :items="valueChains"
            @delete="deleteValueChain"
          />

          <organization-gallery-listing
            title="Value Chain Partners"
            :items="valueChainPartners"
          />
        </v-tab-item>

        <!-- ----------------------------------- Certification ------------------------------------ -->
        <v-tab-item value="certifications">
          <organization-gallery-listing
            title="Certifications"
            :items="certifications"
            :isVerified="isVerified"
            :insertButton="!isEditing || isPublicPage ? false : true"
            :itemOptions="
              isPublicPage
                ? [
                    {
                      text: 'Download',
                      color: '#442D65',
                      onClick: (item) => downloadCertification(item.downloadId),
                    },
                  ]
                : [
                    {
                      text: 'Download',
                      color: '#442D65',
                      onClick: (item) => downloadCertification(item.downloadId),
                    },
                    {
                      text: 'Delete',
                      color: '#EA7A66',
                      onClick: (item) => deleteCertification(item.id),
                    },
                  ]
            "
            @insert-item="showAddNewCertificationForm = true"
          />
        </v-tab-item>
      </v-tabs-items>

      <!-- Add New Member Form -->
      <popup-window
        :active="showAddNewMemberForm"
        :hasBack="false"
        title="Add New Member"
        titleStyle="margin-left: 12px;"
        noMargin
        @popup-close="resetAddNewMember"
        @popup-back="resetAddNewMember"
      >
        <v-form ref="addTeamMemberForm" style="margin-top: 10px">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newMemberDraft.name"
                  label="Name"
                  class="text-field"
                  outlined
                  dense
                  :rules="rules.memberRules.name"
                ></v-text-field>
                <v-text-field
                  v-model="newMemberDraft.designation"
                  label="Designation"
                  class="text-field"
                  outlined
                  dense
                  :rules="rules.memberRules.designation"
                ></v-text-field>
                <v-file-input
                  v-model="newMemberDraft.logo"
                  label="Avatar"
                  outlined
                  dense
                  accept="image/*"
                  :rules="rules.memberRules.logo"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="right">
                <ui-button
                  class="primary-outlined"
                  @click="resetAddNewMember"
                  title="Close"
                />
                <ui-button
                  class="primary"
                  :loading="addingNewMember"
                  @click="addNewTeamMember"
                  title="Save"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </popup-window>

      <!-- Add New Factory/Store Form -->
      <popup-window
        :active="showAddNewFactoryForm"
        :hasBack="false"
        :title="isBrandPage ? 'Add New Store' : 'Add New Factory'"
        titleStyle="margin-left: 12px;"
        noMargin
        @popup-close="resetAddNewFactory"
        @popup-back="resetAddNewFactory"
      >
        <v-form ref="addFactoryForm" style="margin-top: 10px">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newFactoryDraft.name"
                  label="Name"
                  class="text-field"
                  outlined
                  dense
                  :rules="rules.factoryRules.name"
                ></v-text-field>
                <v-autocomplete
                  v-model="newFactoryDraft.establishedCountry"
                  :items="countries"
                  label="Established Country"
                  dense
                  outlined
                  :menu-props="{
                    zIndex: '204 !important',
                  }"
                  :rules="rules.factoryRules.establishedCountry"
                >
                  <template v-slot:item="{ item }">
                    <span class="country-selection-item">{{ item }}</span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="country-selection-item">{{ item }}</span>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model="newFactoryDraft.establishedCity"
                  label="Established City"
                  class="text-field"
                  outlined
                  dense
                  :rules="rules.factoryRules.establishedCity"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="right">
                <ui-button
                  class="primary-outlined"
                  @click="resetAddNewFactory"
                  title="Close"
                />
                <ui-button
                  class="primary"
                  :loading="addingNewFactory"
                  @click="addNewFactory"
                  title="Save"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </popup-window>

      <!-- Add New Product Form -->
      <popup-window
        :active="showAddNewProductForm"
        :hasBack="false"
        title="Add New Product"
        titleStyle="margin-left: 12px;"
        noMargin
        @popup-close="resetAddNewProduct"
        @popup-back="resetAddNewProduct"
      >
        <v-form ref="addProductForm" style="margin-top: 10px">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newProductDraft.caption"
                  label="Caption"
                  class="text-field"
                  outlined
                  dense
                  :rules="rules.productRules.caption"
                ></v-text-field>
                <v-text-field
                  v-model="newProductDraft.description"
                  label="Description"
                  class="text-field"
                  outlined
                  dense
                  :rules="rules.productRules.description"
                ></v-text-field>
                <v-file-input
                  v-model="newProductDraft.image"
                  label="Product image"
                  :rules="rules.productRules.image"
                  outlined
                  dense
                  accept="image/*"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="right">
                <ui-button
                  class="primary-outlined"
                  @click="resetAddNewProduct"
                  title="Close"
                />
                <ui-button
                  class="primary"
                  :loading="addingNewProduct"
                  @click="addNewProduct"
                  title="Save"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </popup-window>

      <!-- Add New Certification Form -->
      <popup-window
        :active="showAddNewCertificationForm"
        :hasBack="false"
        title="Add New Certification"
        titleStyle="margin-left: 12px;"
        noMargin
        @popup-close="resetAddNewCertification"
        @popup-back="resetAddNewCertification"
      >
        <v-form ref="addCertificationForm" style="margin-top: 10px">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="newCertificationDraft.image"
                  label="Certification"
                  :rules="rules.certificationRules.image"
                  outlined
                  dense
                ></v-file-input>
                <v-text-field
                  v-model="newCertificationDraft.caption"
                  label="Caption"
                  class="text-field"
                  outlined
                  dense
                  :rules="rules.certificationRules.caption"
                ></v-text-field>
                <v-text-field
                  v-model="newCertificationDraft.expires"
                  type="date"
                  label="Expiry Date"
                  hint="Keep empty if it doesn't expire"
                  class="text-field"
                  :rules="rules.certificationRules.expireDate"
                  :min="tomorrowDate"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="right">
                <ui-button
                  class="primary-outlined"
                  @click="resetAddNewCertification"
                  title="Close"
                />
                <ui-button
                  class="primary"
                  :loading="addingNewCertification"
                  @click="addNewCertification"
                  title="Save"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </popup-window>

      <!-- Image Preview -->
      <popup-window
        :active="showPreview"
        :fullScreen="true"
        maxWidth="fit-content"
        width="fit-content"
        @popup-close="handlePreview()"
        @popup-back="handlePreview()"
      >
        <div>
          <img :src="previewingFile" style="max-width: 500px" />
        </div>
      </popup-window>
    </v-container>
  </v-app>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import auth from "../../../auth";
import notification from "../../../notification";
import restAdapter from "../../../restAdapter";
import * as conf from "../../../conf.yml";
import countryList from "country-list";
import About from "../../../components/organization/About.vue";
import OrganizationGalleryListing from "../../../components/organization/OrganizationGalleryListing.vue";
import ValueChainListing from "../../../components/organization/ValueChainListing.vue";
import CompanyProfile from "../../../components/organization/CompanyProfile.vue";
import UiButton from "../../../components/base/UiButton.vue";
import routes from "../../../router/routes";
import utils from "../../../utils";
import { isImageFile } from "../../../utils/media-type";
import store from "@/store";
import AnalyticsTile from "../../../components/AnalyticsTile.vue";
import AnalyticsListing from "../../../components/AnalyticsListing.vue";
import debounce from "lodash/debounce";
import _ from "lodash";
import FactoryProfileImagesVue from "./FactoryProfileImages.vue";
import RequestVerificationButton from "@/components/RequestVerificationButton.vue";

export default {
  name: "AccountProfile",
  components: {
    About,
    OrganizationGalleryListing,
    ValueChainListing,
    CompanyProfile,
    UiButton,
    AnalyticsTile,
    AnalyticsListing,
    RequestVerificationButton,
  },
  computed: {
    countries() {
      return countryList.getNames();
    },
    dateRangeLabel() {
      return "timeframe";
    },
    formattedDateRange() {
      const { from, to } = this.dateRange;
      if (from && to) {
        return `Timeframe: ${this.formatDate(from)} - ${this.formatDate(to)}`;
      }
      return "Select timeframe";
    },
    isDateRangeValid() {
      const { from, to } = this.dateRange;
      if (from && to) {
        return new Date(from) <= new Date(to);
      }
      return true;
    },
    tilesArray() {
      return Object.values(this.productTiles);
    },
    listingArray() {
      return Object.values(this.userListing);
    },
  },
  data() {
    return {
      activeTab: "home",
      isBrandPage: false,
      selectedDateRange: "default",
      dateRange: {
        from: null,
        to: null,
      },
      selectedTab: 1,
      tabOptions: [
        "Custom",
        "1 month",
        "2 weeks",
        "3 months",
        "6 months",
        "1 year",
      ],
      productTilesLoading: false,
      productTiles: {
        createdCollections: {
          title: "Created Collections",
          count: 0,
          percentage: null,
          type: "product",
        },
        completedCollections: {
          title: "Completed Collections",
          count: 0,
          percentage: null,
          type: "product",
        },
        createdStyles: {
          title: "Created Styles",
          count: 0,
          percentage: null,
          type: "product",
        },
        completedStyles: {
          title: "Completed Styles",
          count: 0,
          percentage: null,
          type: "product",
        },
        totalProducts: {
          title: "Total Products",
          count: 0,
          percentage: null,
          type: "product",
        },
        dppCreated: {
          title: "DPP Created",
          count: 0,
          percentage: null,
          type: "product",
        },
        dppPublished: {
          title: "DPP Published",
          count: 0,
          percentage: null,
          type: "product",
        },
        updatesPublishedDpp: {
          title: "Updates Published DPP",
          count: 0,
          percentage: null,
          type: "product",
        },
        totalTraceableProducts: {
          title: "Total Traceable Products",
          count: 0,
          percentage: null,
          type: "product",
        },
      },
      userListing: {
        activeUsersListing: {
          title: "Most Active Users (Messages engagements)",
          data: [],
          loading: false,
          total: 0,
          page: 1,
          loadPage: "getActiveUsersListing",
        },
        mobileAppUsageListing: {
          title: "Mobile App Usage",
          data: [],
          loading: false,
          total: 0,
          page: 1,
          loadPage: "getMobileAppUsageListing",
        },
      },
      dateMenuVisible: false,
      today: new Date().toISOString().split("T")[0],
      dateRangeOptions: [{ text: "Select timeframe", value: "default" }],
      tomorrowDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0],
      showPreview: false,
      previewingFile: "",
      organizationDetails: {
        id: "",
        name: "",
        type: "",
      },
      accountDetails: {
        logo: "",
        cover: "",
        website: "",
        about: "",
        registrationNumber: "",
        establishedCity: "",
        establishedCountry: "",
        establishedYear: "",
        vision: "",
        mission: "",
        contactEmail: "",
        contactMobile: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
      },
      newMemberDraft: {
        name: "",
        designation: "",
        logo: null,
      },
      newFactoryDraft: {
        name: "",
        establishedCountry: null,
        establishedCity: "",
      },
      newProductDraft: {
        caption: "",
        description: "",
        image: null,
      },
      newCertificationDraft: {
        caption: "",
        image: null,
        expires: null,
      },
      rules: {
        memberRules: {
          name: [
            (v) => !!v || "Name is required",
            (v) =>
              (v && v.length <= 255) || "Name must be less than 255 characters",
          ],
          designation: [
            (v) => !!v || "Designation is required",
            (v) =>
              (v && v.length <= 255) ||
              "Designation must be less than 255 characters",
          ],
          logo: [
            (v) =>
              !v ||
              (v && v.size <= 2 * 1024 * 1024) ||
              "Avatar size should be less than 2MB",
          ],
        },
        factoryRules: {
          name: [
            (v) =>
              !!v ||
              (this.isBrandPage
                ? "Store name is required"
                : "Factory name is required"),
            (v) =>
              (v && v.length <= 200) ||
              (this.isBrandPage
                ? "Store name must not exceed 200 characters"
                : "Factory name must not exceed 200 characters"),
          ],
          establishedCity: [
            (v) => !!v || "Established City is required",
            (v) =>
              (v && v.length <= 200) ||
              "Established city must not exceed 200 characters",
          ],
          establishedCountry: [(v) => !!v || "Established Country is required"],
        },
        productRules: {
          caption: [
            (v) => !!v || "Caption is required",
            (v) =>
              (v && v.length <= 255) ||
              "Caption must be less than 255 characters",
          ],
          description: [
            (v) =>
              !v ||
              (v && v.length <= 255) ||
              "Description must be less than 255 characters",
          ],
          image: [
            (v) => !!v || "Image is required",
            (v) =>
              (v && v.size <= 2 * 1024 * 1024) ||
              "Image size should be less than 2MB",
          ],
        },
        certificationRules: {
          caption: [
            (v) => !!v || "Caption is required",
            (v) =>
              (v && v.length <= 255) ||
              "Caption must be less than 255 characters",
          ],
          image: [
            (v) => !!v || "Certification is required",
            (v) =>
              (v && v.size <= 2 * 1024 * 1024) ||
              "Certification size should be less than 2MB",
            (v) => {
              if (!v) {
                return true;
              }
              const extension =
                v.name.match(/\.[0-9a-z]+$/i) &&
                v.name.match(/\.[0-9a-z]+$/i)[0];
              const supportedFileTypes = utils.acceptedFileTypes.split(",");
              return (
                supportedFileTypes.includes(extension) || "Invalid file type"
              );
            },
          ],
          expireDate: [
            (v) =>
              !v ||
              (v && new Date(v) > new Date()) ||
              "Expiry date must be in the future.",
          ],
        },
      },
      teamMembers: [],
      productImages: [],
      factories: [],
      valueChains: [],
      valueChainPartners: [],
      certifications: [],
      baseUrl: null,
      maxFileSize: 10 * 1024 * 1024,
      isPublicPage: true,
      isAccountPrivate: false,
      isVerified: false,
      isEditing: false,
      accountUuid: null,
      isOwner: false,
      isSuperAdmin: false,
      companyUuid: null,
      aboutExpanded: false,
      showAddNewMemberForm: false,
      showAddNewFactoryForm: false,
      showAddNewProductForm: false,
      showAddNewCertificationForm: false,
      addingNewMember: false,
      addingNewFactory: false,
      addingNewProduct: false,
      addingNewCertification: false,
    };
  },
  watch: {
    dateRange: {
      handler() {
        this.debounceAnalytics();
      },
      deep: true,
    },
    activeTab() {
      this.debounceAnalytics();
    },
  },
  mounted() {
    this.initialize();
    this.setDefaultDateRange();
    this.getAccount();
  },
  methods: {
    loadListingPage(method, page) {
      this[method](page);
    },
    toggleAboutExpanded() {
      this.aboutExpanded = !this.aboutExpanded;
    },
    debounceAnalytics: debounce(function () {
      this.initializeAnalytics();
    }, 500),
    initialize() {
      this.baseUrl = conf.default.server.host;
      if (this.$route.params?.companyUuid) {
        this.isPublicPage = true;
        this.companyUuid = this.$route.params.companyUuid;
        this.isSuperAdmin = this.$userPermissions.getIsSuperAdmin();
        this.getOrganization(this.$route.params.companyUuid);
        this.isOwner = false;
      } else if (!this.$userPermissions.getIsSuperAdmin()) {
        this.accountUuid = auth.user.account.uuid;
        this.isPublicPage = false;
        this.isOwner = true;
        this.retrieveAccountDetails();
      }
    },
    setDefaultDateRange() {
      const today = new Date();
      const toDate = new Date(today);
      toDate.setDate(today.getDate());
      const toDateFormatted = toDate.toISOString().split("T")[0];

      const fromDate = new Date(today);
      fromDate.setDate(fromDate.getDate() - 30);
      const fromDateFormatted = fromDate.toISOString().split("T")[0];

      this.dateRange.from = fromDateFormatted;
      this.dateRange.to = toDateFormatted;
      this.dateRangeOptions[0].text = `Timeframe: ${fromDateFormatted} - ${toDateFormatted}`;
    },
    openDatePicker() {
      this.dateMenuVisible = true;
    },
    closeDatePicker() {
      this.dateMenuVisible = false;
    },
    formatDate(date) {
      if (!date) return null;
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      return date.toISOString().split("T")[0];
    },
    clearDateRange() {
      this.selectedDateRange = "default";
      this.dateRange = { from: null, to: null };
      this.dateRangeOptions[0].text = "Select timeframe";
    },
    selectTab(option = null) {
      this.selectedTab = option;
      const today = new Date();
      let fromDate;

      if (option === "Custom") {
        fromDate = new Date(today);
        fromDate.setMonth(today.getMonth() - 1);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRange.from = this.formatDate(fromDate);
        this.dateRange.to = this.formatDate(today);
      } else if (option === "1 month") {
        fromDate = new Date(today);
        fromDate.setMonth(today.getMonth() - 1);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRange.from = this.formatDate(fromDate);
        this.dateRange.to = this.formatDate(today);
      } else if (option === "2 weeks") {
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 13);
        this.dateRange.from = this.formatDate(fromDate);
        this.dateRange.to = this.formatDate(today);
      } else if (option === "3 months") {
        fromDate = new Date(today);
        fromDate.setMonth(today.getMonth() - 3);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRange.from = this.formatDate(fromDate);
        this.dateRange.to = this.formatDate(today);
      } else if (option === "6 months") {
        fromDate = new Date(today);
        fromDate.setMonth(today.getMonth() - 6);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRange.from = this.formatDate(fromDate);
        this.dateRange.to = this.formatDate(today);
      } else if (option === "1 year") {
        fromDate = new Date(today);
        fromDate.setFullYear(today.getFullYear() - 1);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRange.from = this.formatDate(fromDate);
        this.dateRange.to = this.formatDate(today);
      }

      this.dateRangeOptions[0].text = `Timeframe: ${this.dateRange.from} - ${this.dateRange.to}`;
    },
    handlePreview(src) {
      if (src) {
        this.showPreview = true;
        this.previewingFile = src;
      } else {
        this.showPreview = false;
        this.previewingFile = "";
      }
    },
    isImage(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) && filename.match(/\.[0-9a-z]+$/i)[0];
      return isImageFile({ type: extension });
    },
    retrieveAccountDetails() {
      this.getAccount();
      this.getTeamMembers();
      this.getProductImages();
      this.getFactories();
      this.getValueChains();
      this.getValueChainPartners();
      this.getCertifications();
    },
    initializeAnalytics() {
      const { from, to } = this.dateRange;
      if (this.activeTab === "analytics" && ((from && to) || (!from && !to))) {
        this.getProductTiles();
        this.getActiveUsersListing(this.userListing.activeUsersListing.page);
        this.getMobileAppUsageListing(
          this.userListing.mobileAppUsageListing.page
        );
      }
    },
    checkIsFiltersMatching(used, current) {
      const usedFilters = { ...used };
      const currentFilters = { ...current };
      if (
        (!currentFilters.from || !currentFilters.to) &&
        (!usedFilters.from || !usedFilters.to)
      ) {
        usedFilters.from = null;
        usedFilters.to = null;
        currentFilters.from = null;
        currentFilters.to = null;
      }

      return Object.keys(usedFilters).every(
        (key) => usedFilters[key] === currentFilters[key]
      );
    },
    async getProductTiles() {
      this.productTilesLoading = true;
      const filters = {
        from: this.dateRange.from,
        to: this.dateRange.to,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/products${
            this.$route.params?.companyUuid
              ? `?uuid=${this.$route.params.companyUuid}`
              : ""
          }${
            this.dateRange.from && this.dateRange.to
              ? `${this.$route.params?.companyUuid ? "&" : "?"}start=${
                  this.dateRange.from
                }&end=${this.dateRange.to}`
              : ""
          }`
        );
        const currentFilters = {
          from: this.dateRange.from,
          to: this.dateRange.to,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.productTilesLoading = false;
        this.productTiles = {
          ...this.productTiles,
          createdCollections: {
            ...this.productTiles.createdCollections,
            ...response.data.created_collections,
          },
          completedCollections: {
            ...this.productTiles.completedCollections,
            ...response.data.completed_collections,
          },
          createdStyles: {
            ...this.productTiles.createdStyles,
            ...response.data.created_inquiries,
          },
          completedStyles: {
            ...this.productTiles.completedStyles,
            ...response.data.completed_inquiries,
          },
          totalProducts: {
            ...this.productTiles.totalProducts,
            ...response.data.total_products,
          },
          dppCreated: {
            ...this.productTiles.dppCreated,
            ...response.data.created_traces,
          },
          dppPublished: {
            ...this.productTiles.dppPublished,
            ...response.data.published_traces,
          },
          updatesPublishedDpp: {
            ...this.productTiles.updatesPublishedDpp,
            ...response.data.changes_published_traces,
          },
          totalTraceableProducts: {
            ...this.productTiles.totalTraceableProducts,
            ...response.data.traceable_products,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getActiveUsersListing(page = 1) {
      this.userListing.activeUsersListing.loading = true;
      this.userListing.activeUsersListing.page = page;
      const filters = {
        from: this.dateRange.from,
        to: this.dateRange.to,
        page: this.userListing.activeUsersListing.page,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/listing/interactions?page=${page}${
            this.$route.params?.companyUuid
              ? `&uuid=${this.$route.params.companyUuid}`
              : ""
          }${
            this.dateRange.from && this.dateRange.to
              ? `&start=${this.dateRange.from}&end=${this.dateRange.to}`
              : ""
          }`
        );
        const currentFilters = {
          from: this.dateRange.from,
          to: this.dateRange.to,
          page: this.userListing.activeUsersListing.page,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        const data = await Promise.all(
          response.data.data.map(async (item) => ({
            ...item,
            id: item.id,
            name: item.full_name,
            position: item.current_position,
            avatar: await this.getUserImage(item.id),
            up: item.previous_position
              ? item.previous_position > item.current_position
              : false,
            down: item.previous_position
              ? item.previous_position < item.current_position
              : false,
            same: item.previous_position
              ? item.previous_position === item.current_position
              : false,
            details: [`${item.messages_count} Interactions`],
          }))
        );
        this.userListing = {
          ...this.userListing,
          activeUsersListing: {
            ...this.userListing.activeUsersListing,
            page: page,
            total: response.data.total,
            data: data,
            loading: false,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getMobileAppUsageListing(page = 1) {
      this.userListing.mobileAppUsageListing.loading = true;
      this.userListing.mobileAppUsageListing.page = page;
      const filters = {
        from: this.dateRange.from,
        to: this.dateRange.to,
        page: this.userListing.mobileAppUsageListing.page,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/listing/mobile?page=${page}${
            this.$route.params?.companyUuid
              ? `&uuid=${this.$route.params.companyUuid}`
              : ""
          }${
            this.dateRange.from && this.dateRange.to
              ? `&start=${this.dateRange.from}&end=${this.dateRange.to}`
              : ""
          }`
        );
        const currentFilters = {
          from: this.dateRange.from,
          to: this.dateRange.to,
          page: this.userListing.mobileAppUsageListing.page,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        const data = await Promise.all(
          response.data.data.map(async (item) => ({
            ...item,
            id: item.id,
            name: item.full_name,
            position: item.current_position,
            avatar: await this.getUserImage(item.id),
            details: [`${item.mobile_push_tokens_count} Devices`],
          }))
        );
        this.userListing = {
          ...this.userListing,
          mobileAppUsageListing: {
            ...this.userListing.mobileAppUsageListing,
            page: page,
            total: response.data.total,
            data: data,
            loading: false,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getUserImage(id) {
      let avatar = null;
      try {
        const avatarResponse = await restAdapter.get(
          `/api/user/image_show/${id}`
        );
        avatar = avatarResponse.data.image;
      } catch (error) {
        avatar = null;
      }
      return avatar || require("../../../assets/chat/person.png");
    },
    getOrganization(uuid) {
      restAdapter.get(`/api/organization_registry/${uuid}`).then((res) => {
        this.organizationDetails.name = res.data.organization.name;
        this.organizationDetails.id = res.data.organization.id;
        this.organizationDetails.type = res.data.organization.type_details.name;
        this.isBrandPage =
          res.data.organization.type_details.short_code === "brand";
        if (res.data.organization.account) {
          this.accountDetails = {
            ...this.accountDetails,
            logo: res.data.organization.account.logo
              ? `${this.baseUrl}/api/account/logo_show/${res.data.organization.account.uuid}/${res.data.organization.account.logo}`
              : null,
            cover: res.data.organization.account.cover
              ? `${this.baseUrl}/api/account/cover_show/${res.data.organization.account.uuid}/${res.data.organization.account.cover}`
              : null,
          };
        }
        if (
          res.data.organization.account &&
          !res.data.organization.account.is_private
        ) {
          this.accountUuid = res.data.organization.account.uuid;
          this.retrieveAccountDetails();
        } else if (this.isSuperAdmin) {
          this.getFactories();
        }
      });
    },
    editProfile() {
      this.isEditing = true;
    },
    requestVerificationForAccount(id) {
      restAdapter
        .post(`/api/organization_verification_requests/${id}`)
        .then(() => {
          store.commit("SET_ACCOUNT_VERIFICATION_REQUESTED", true);
          notification.success(
            "Profile Verification request sent to Seamless Source"
          );
        })
        .catch((error) => {
          notification.error("Something went wrong, please try again later.");
        });
    },

    getAccount() {
      restAdapter
        .get(
          `/api/accounts/${this.isPublicPage ? "public/" : ""}${
            this.accountUuid
          }`
        )
        .then((res) => {
          this.organizationDetails.name =
            res.data.account.organization_registry.name;
          this.organizationDetails.id =
            res.data.account.organization_registry.id;
          this.organizationDetails.type =
            res.data.account.organization_registry.type_details.name;
          this.isBrandPage =
            res.data.account.organization_registry.type_details.short_code ===
            "brand";
          this.companyUuid = res.data.account.organization_registry.uuid;
          this.isAccountPrivate = res.data.account.is_private;
          this.isVerified = res.data.account.verified;
          this.isEditing = !this.isVerified;
          this.accountDetails = {
            ...this.accountDetails,
            logo: res.data.account.logo
              ? `${this.baseUrl}/api/account/logo_show/${this.accountUuid}/${res.data.account.logo}`
              : null,
            cover: res.data.account.cover
              ? `${this.baseUrl}/api/account/cover_show/${this.accountUuid}/${res.data.account.cover}`
              : null,
            website: res.data.account.website,
            about: res.data.account.about,
            registrationNumber: res.data.account.registration_number,
            establishedCity: res.data.account.established_city,
            establishedCountry: res.data.account.established_country,
            establishedYear: res.data.account.established_year,
            vision: res.data.account.vision,
            mission: res.data.account.mission,
            contactEmail: res.data.account.contact_email,
            contactMobile: res.data.account.contact_mobile,
            addressLine1: res.data.account.address_line1,
            addressLine2: res.data.account.address_line2,
            city: res.data.account.city,
            state: res.data.account.state,
            country: res.data.account.country,
            postalCode: res.data.account.postal_code,
          };

          const isCityAndCountryFilled =
            !!this.accountDetails.city && !!this.accountDetails.country;
          store.commit(
            "SET_CITY_AND_COUNTRY_IS_FILLED",
            isCityAndCountryFilled
          );
        });
    },
    getTeamMembers() {
      restAdapter
        .get(
          `/api/accounts/${this.isPublicPage ? "public/" : ""}${
            this.accountUuid
          }/team_members`
        )
        .then((res) => {
          this.teamMembers = res.data.team_members.map((member) => ({
            id: member.id,
            title: member.name,
            description: member.title,
            url: member.logo
              ? `${this.baseUrl}/api/account/team_member_logo_show/${this.accountUuid}/${member.logo}`
              : require("../../../assets/chat/person.png"),
          }));
        });
    },
    addNewTeamMember() {
      if (!this.$refs.addTeamMemberForm.validate()) {
        return;
      }

      this.addingNewMember = true;
      const formData = new FormData();
      formData.append("name", this.newMemberDraft.name);
      formData.append("title", this.newMemberDraft.designation);
      this.newMemberDraft.logo &&
        formData.append("logo", this.newMemberDraft.logo);

      restAdapter
        .post(`/api/accounts/${this.accountUuid}/team_members`, formData)
        .then(() => {
          this.getTeamMembers();
          this.resetAddNewMember();
          notification.success("Team member added successfully");
        })
        .catch(() => {
          this.resetAddNewMember();
          notification.error("Failed to add team member");
        });
    },
    resetAddNewMember() {
      this.addingNewMember = false;
      this.showAddNewMemberForm = false;
      this.newMemberDraft = {
        name: "",
        designation: "",
        logo: null,
      };
      this.$refs.addTeamMemberForm.resetValidation();
    },
    deleteTeamMember(id) {
      const confirmDelete = confirm("Are you sure you want to delete?");
      if (!confirmDelete) {
        return;
      }
      restAdapter
        .delete(`/api/accounts/${this.accountUuid}/team_members/${id}`)
        .then(() => {
          this.getTeamMembers();
          notification.success("Team member deleted successfully");
        });
    },
    getProductImages() {
      restAdapter
        .get(
          `/api/accounts/${this.isPublicPage ? "public/" : ""}${
            this.accountUuid
          }/gallery_images`
        )
        .then((res) => {
          this.productImages = res.data.gallery_images.map((product) => {
            const previewSrc = `${this.baseUrl}/api/account/gallery_show/${this.accountUuid}/${product.upload_name}`;
            return {
              id: product.id,
              ...(this.isImage(product.upload_name)
                ? {
                    onClick: () => this.handlePreview(previewSrc),
                  }
                : {}),
              title: product.caption,
              description: product.description,
              url: previewSrc,
            };
          });
        });
    },
    addNewProduct() {
      if (!this.$refs.addProductForm.validate()) {
        return;
      }

      this.addingNewProduct = true;
      const formData = new FormData();
      formData.append("caption", this.newProductDraft.caption);
      formData.append("description", this.newProductDraft.description);
      formData.append("image", this.newProductDraft.image);

      restAdapter
        .post(`/api/accounts/${this.accountUuid}/gallery_images`, formData)
        .then(() => {
          this.getProductImages();
          this.resetAddNewProduct();
          notification.success("Product added successfully");
        })
        .catch(() => {
          this.resetAddNewProduct();
          notification.error("Failed to add product");
        });
    },
    resetAddNewProduct() {
      this.addingNewProduct = false;
      this.showAddNewProductForm = false;
      this.newProductDraft = {
        caption: "",
        description: "",
        image: null,
      };
      this.$refs.addProductForm.resetValidation();
    },
    deleteProduct(id) {
      const confirmDelete = confirm("Are you sure you want to delete?");
      if (!confirmDelete) {
        return;
      }
      restAdapter
        .delete(`/api/accounts/${this.accountUuid}/gallery_images/${id}`)
        .then(() => {
          this.getProductImages();
          notification.success("Product deleted successfully");
        });
    },
    getFactories() {
      restAdapter
        .get(
          !this.isSuperAdmin
            ? `/api/accounts/${this.isPublicPage ? "public/" : ""}${
                this.accountUuid
              }/factories`
            : `/api/organization_registry/${this.organizationDetails.id}/factories`
        )
        .then((res) => {
          this.factories = res.data.factories.map((factory) => {
            return {
              id: factory.id,
              ...(!this.isPublicPage
                ? {
                    onClick: () => {
                      if (this.isVerified) {
                        alert(
                          "Editing factory details will cause your profile to become unverified and it will affect all transactions associated with this profile in published Digital Product Passports.\n\nPlease request for verification again after you have edited the details."
                        );
                      }
                      this.$router.push({
                        name: routes.FACTORY_PROFILE,
                        params: { id: factory.id },
                      });
                    },
                  }
                : {}),
              title: factory.name,
              description:
                factory.established_city && factory.established_country
                  ? `${factory.established_city}, ${factory.established_country}`
                  : "",
              url: factory.logo
                ? `${this.baseUrl}/api/factories/logo_show/${factory.logo}`
                : this.isBrandPage
                ? require("../../../assets/store.png")
                : require("../../../assets/factory.png"),
            };
          });
        });
    },
    addNewFactory() {
      if (!this.$refs.addFactoryForm.validate()) {
        return;
      }

      this.addingNewFactory = true;
      const formData = new FormData();
      formData.append("global_organization_id", this.organizationDetails.id);
      formData.append("name", this.newFactoryDraft.name);
      formData.append(
        "established_country",
        this.newFactoryDraft.establishedCountry
      );
      formData.append("established_city", this.newFactoryDraft.establishedCity);

      restAdapter
        .post(`/api/factories`, formData)
        .then((res) => {
          this.resetAddNewFactory();
          if (!this.isSuperAdmin) {
            this.navigateToFactory(res.data.factory_id);
          } else {
            this.isVerified = false;
          }
          notification.success(
            `${this.isBrandPage ? "Store" : "Factory"} added successfully`
          );
          this.getFactories();
        })
        .catch(() => {
          this.resetAddNewFactory();
          notification.error(
            `Failed to add ${this.isBrandPage ? "store" : "factory"}`
          );
        });
    },
    resetAddNewFactory() {
      this.addingNewFactory = false;
      this.showAddNewFactoryForm = false;
      this.newFactoryDraft = {
        name: "",
        establishedCountry: null,
        establishedCity: "",
      };
      this.$refs.addFactoryForm.resetValidation();
    },
    navigateToFactory(id) {
      if (this.isVerified) {
        alert(
          "Editing factory details will cause your profile to become unverified and it will affect all transactions associated with this profile in published Digital Product Passports.\n\nPlease request for verification again after you have edited the details."
        );
      }
      this.$router.push({
        name: routes.FACTORY_PROFILE,
        params: { id },
      });
    },
    deleteFactory(id) {
      if (this.isVerified) {
        alert(
          "Deleting factories will cause your profile to become unverified and it will affect all transactions associated with this profile in published Digital Product Passports.\n\nPlease request for verification again after you have deleted the factory."
        );
      }
      const confirmDelete = confirm(
        this.isBrandPage
          ? "Are you sure you want to delete?"
          : "This factory will be removed from every place this was added including any published product passports. Are you sure you want to delete?"
      );
      if (!confirmDelete) {
        return;
      }
      restAdapter.delete(`/api/factories/${id}`).then(() => {
        this.getFactories();
        notification.success(
          `${this.isBrandPage ? "Store" : "Factory"} deleted successfully`
        );
      });
    },
    getValueChains() {
      restAdapter
        .get(
          `/api/accounts/${this.isPublicPage ? "public/" : ""}${
            this.accountUuid
          }/value_chains`
        )
        .then((res) => {
          this.valueChains = res.data.value_chains.map((valueChain) => {
            const traces = {};
            valueChain.value_chain_traces.forEach((trace) => {
              traces[_.camelCase(trace.trace.display_name)] = trace.supplier;
            });

            return {
              id: valueChain.id,
              name: valueChain.name,
              productType: valueChain.product_type.name,
              productTypeId: valueChain.product_type.id,
              description: valueChain.description,
              values: {
                donations: valueChain.value_chain_donations.map(
                  (donation) => donation.supplier
                ),
                repairs: valueChain.value_chain_repairs.map(
                  (repair) => repair.supplier
                ),
                upcycles: valueChain.value_chain_upcycles.map(
                  (upcycle) => upcycle.supplier
                ),
                recycles: valueChain.value_chain_recycles.map(
                  (recycle) => recycle.supplier
                ),
                resales: valueChain.value_chain_resales.map(
                  (resale) => resale.supplier
                ),
                ...traces,
              },
            };
          });
        });
    },
    deleteValueChain(id) {
      const confirmDelete = confirm("Are you sure you want to delete?");
      if (!confirmDelete) {
        return;
      }
      restAdapter
        .delete(`/api/accounts/${this.accountUuid}/value_chains/${id}`)
        .then(() => {
          this.getValueChains();
          notification.success("Value chain deleted successfully");
        });
    },
    getValueChainPartners() {
      restAdapter
        .get(
          `/api/accounts/${this.isPublicPage ? "public/" : ""}${
            this.accountUuid
          }/value_chain_partners`
        )
        .then((res) => {
          this.valueChainPartners = res.data.partners.map((partner) => ({
            id: partner.id,
            uuid: partner.uuid,
            onClick: () => {
              this.$router.push({
                name: routes.COMPANY_PROFILE,
                params: { companyUuid: partner.uuid },
              });
            },
            title: partner.name,
            verified: partner.account && partner.account.verified,
            description:
              partner.account && partner.account.city && partner.account.country
                ? `${partner.account.city}, ${partner.account.country}`
                : "",
            url:
              partner.account && partner.account.logo
                ? `${this.baseUrl}/api/account/logo_show/${partner.account.uuid}/${partner.account.logo}`
                : require("../../../assets/company.png"),
          }));
        });
    },
    getCertifications() {
      restAdapter
        .get(
          `/api/accounts/${this.isPublicPage ? "public/" : ""}${
            this.accountUuid
          }/certifications`
        )
        .then((res) => {
          this.certifications = res.data.certifications.map((certification) => {
            const expired = certification.expire_date
              ? new Date(certification.expire_date) <= new Date()
              : false;
            const previewSrc = `${this.baseUrl}/api/account/certification_show/${this.accountUuid}/${certification.upload_name}`;
            return {
              id: certification.id,
              ...(this.isImage(certification.upload_name)
                ? {
                    onClick: () => this.handlePreview(previewSrc),
                  }
                : {}),
              title: certification.caption,
              description: certification.expire_date
                ? `${expired ? "Expired" : "Expires"} on ${new Date(
                    certification.expire_date
                  ).toDateString()}`
                : "",
              url: previewSrc,
              downloadId: certification.upload_name,
              seal: expired ? "Expired" : "",
            };
          });
        });
    },
    addNewCertification() {
      if (!this.$refs.addCertificationForm.validate()) {
        return;
      }

      this.addingNewCertification = true;
      const formData = new FormData();
      formData.append("caption", this.newCertificationDraft.caption);
      formData.append("certification", this.newCertificationDraft.image);
      this.newCertificationDraft.expires &&
        formData.append("expire_date", this.newCertificationDraft.expires);

      restAdapter
        .post(`/api/accounts/${this.accountUuid}/certifications`, formData)
        .then(() => {
          this.getCertifications();
          this.resetAddNewCertification();
          this.isVerified = false;
          notification.success("Certification added successfully");
        })
        .catch(() => {
          this.resetAddNewCertification();
          notification.error("Failed to add certification");
        });
    },
    resetAddNewCertification() {
      this.addingNewCertification = false;
      this.showAddNewCertificationForm = false;
      this.newCertificationDraft = {
        caption: "",
        image: null,
        expires: null,
      };
      this.$refs.addCertificationForm.resetValidation();
    },
    deleteCertification(id) {
      if (this.isVerified) {
        alert(
          "Deleting certifications will cause your profile to become unverified and it will affect all transactions associated with this profile in published Digital Product Passports.\n\nPlease request for verification again after you have deleted the certification."
        );
      }
      const confirmDelete = confirm("Are you sure you want to delete?");
      if (!confirmDelete) {
        return;
      }
      restAdapter
        .delete(`/api/accounts/${this.accountUuid}/certifications/${id}`)
        .then(() => {
          this.getCertifications();
          notification.success("Certification deleted successfully");
        });
    },
    downloadCertification(id) {
      window.open(
        `${this.baseUrl}/api/account/certification_download/${this.accountUuid}/${id}`
      );
    },
    updateAccount(details) {
      const payload = {
        website: details.website,
        about: details.about,
        registration_number: details.registrationNumber,
        established_city: details.establishedCity,
        established_year: details.establishedYear,
        vision: details.vision,
        mission: details.mission,
        contact_email: details.email,
        contact_mobile: details.mobile,
        address_line1: details.addressLine1,
        address_line2: details.addressLine2,
        city: details.city,
        state: details.state,
        country: details.country,
        postal_code: details.postalCode,
      };

      restAdapter
        .post(`/api/accounts/${this.accountUuid}/update`, payload)
        .then(() => {
          this.accountDetails = {
            ...this.accountDetails,
            ...details,
            contactEmail: details.email,
            contactMobile: details.mobile,
          };
          this.isVerified = false;
          store.commit(
            "SET_CITY_AND_COUNTRY_IS_FILLED",
            !!details.city && !!details.country
          );
          notification.success("Details updated successfully");
        });
    },
    updateLogo(file) {
      if (file.size > 2 * 1024 * 1024) {
        notification.error("Logo size should be less than 2MB");
        return;
      }

      const formData = new FormData();
      formData.append("logo", file);

      restAdapter
        .post(`/api/accounts/${this.accountUuid}/update_logo`, formData)
        .then(() => {
          this.getAccount();
          notification.success("Logo updated successfully");
        });
    },
    updateCover(file) {
      if (file.size > 2 * 1024 * 1024) {
        notification.error("Cover size should be less than 2MB");
        return;
      }

      const formData = new FormData();
      formData.append("cover", file);

      restAdapter
        .post(`/api/accounts/${this.accountUuid}/update_cover`, formData)
        .then(() => {
          this.getAccount();
          notification.success("Cover updated successfully");
        });
    },
    toggleAccountPrivateStatus() {
      restAdapter
        .put(`/api/accounts/${this.accountUuid}/update_private_status`, {
          is_private: !this.isAccountPrivate,
        })
        .then(() => {
          this.isAccountPrivate = !this.isAccountPrivate;
          notification.success(
            `Account is now ${this.isAccountPrivate ? "private" : "public"}`
          );
        });
    },
    copyLink() {
      navigator.clipboard.writeText(
        `${window.location.origin}/#/company/${this.companyUuid}`
      );
      notification.success("Link copied to clipboard");
    },
  },
};
</script>

<style lang="scss" scoped>
.row-alignment {
  margin: -12px 20px;
}

.filter-row {
  display: flex;
  align-items: center;
  gap: 16px;
}
.centered-card {
  margin: 8px;
  padding: 8px;
}

.date-input {
  margin-top: 8px;
}

.tabs-column {
  padding: 0;
}

.tab-item {
  font-size: 0.875rem;
  padding: 4px 8px;
}

.divider {
  margin: 8px 0;
  height: calc(100% - 16px);
}
.date-input {
  margin-top: 5px;
}
.filter-select {
  background-color: #eff0f6;
  border-radius: 40px;
  max-width: 330px;
  width: fit-content;
  color: #473068;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  border: none !important;
  outline: none !important;
}

.listings-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.filter-type {
  background-color: #eff0f6;
  border-radius: 40px;
  max-width: 220px;
  width: fit-content;
  color: #473068;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  border: none !important;
  outline: none !important;
}

.v-card-title,
.v-text-field .v-label,
.v-text-field .v-input__control .v-input__slot input {
  color: #473068 !important;
}

.v-card {
  padding: 20px;
}

.date-input {
  border: none !important;
}

.v-btn {
  color: #473068 !important;
}

.v-alert {
  color: #473068 !important;
}

.v-card-title {
  font-weight: bold;
  font-size: 16px;
}

.centered-card {
  margin: auto;
  color: #473068 !important;
}

.container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.card-height {
  min-height: 60vh;
}

.centered-content {
  margin: 0 auto;
}

.filter-row {
  display: flex;
  justify-content: left;
  align-items: left;
  margin: 20px;
}

.date-chip-container {
  margin: 0 10px;
}

.date-label {
  font-weight: 500;
  color: #473068;
  font-size: 13pt;
}

.filter-chip {
  cursor: pointer;
  padding: 8px;
  border-radius: 16px;
  background-color: #f5f5f5;
  font-size: large;
  color: #473068;
}

.date-input {
  margin-bottom: 10px;
  color: #473068;
}

.v-card-title {
  font-weight: bold;
  font-size: 16px;
  color: #473068;
}

.v-card {
  padding: 20px;
}

.v-date-picker {
  width: 100%;
}

.v-chip__content {
  font-size: 16px;
  font-weight: 500;
}

.v-btn.submit-button {
  margin-top: 10px;
  color: #473068 !important;
  background-color: #ffffff !important;
}

.date-error {
  margin-top: 10px;
  color: #ffffff;
}

.label {
  flex: 1;
  font-weight: bold;
  text-align: left;
}

.separator {
  margin: 0 10px;
  text-align: center;
}
.v-input--is-focused::v-deep fieldset,
.v-input--has-state::v-deep fieldset {
  border: 2px solid #473068 !important;
}
.text-field::v-deep .v-messages__message {
  margin-left: -12px;
  color: #473068;
}
.text-field::v-deep .error--text .v-messages__message {
  color: $danger !important;
}
.v-autocomplete::v-deep label {
  color: #473068 !important;
}
.v-autocomplete::v-deep i {
  color: #473068 !important;
}
.v-file-input::v-deep .error--text .v-messages__message {
  margin-left: -12px;
  color: $danger !important;
}
.v-file-input::v-deep label {
  color: #473068 !important;
}
.v-file-input::v-deep button {
  color: #473068 !important;
}
.v-file-input::v-deep .error--text .v-messages__message {
  margin-left: -12px;
  color: $danger !important;
}
.text-field::v-deep input,
.text-field::v-deep label {
  color: #473068 !important;
}
.v-text-field--outlined::v-deep fieldset {
  border: 1px solid #ccc;
  color: #473068;
}
.text-field-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.popup-card {
  padding: 20px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.verify-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.verify-btn {
  margin-left: 10px;
}

.row-alignment {
  margin: -12px 20px;
}

.md-button.primary-outlined.md-theme-default.primary {
  background-color: unset !important;
  border-color: unset !important;
}

.container-card {
  max-width: 100%;
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  padding-bottom: 30px;
  border: 1px solid $tableBorder;
  @media (min-width: 768px) {
    max-width: 800px;
    margin: 0 auto;
  }
  padding: 15px;
}

.card-height {
  min-height: 60vh;
}

.assignment-container {
  overflow-x: auto;
  white-space: nowrap;
  max-height: 300px;
}

.no-assignments-message {
  color: #2c3e50;
  text-align: center;
  padding: 20px;
  min-height: 100px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.horizontal-table {
  display: flex;
  flex-direction: row;
}

.table-row {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.v-list-item {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.1);
  border: 1px solid $tableBorder;
  border-radius: 5px;
  margin-bottom: 10px;
}

.title_style {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $tableBorder;
  text-decoration-line: underline;
  text-align: left;
}

.table-cell {
  margin-bottom: 8px;
}

.assignment-card {
  margin-top: 20px;
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  padding: 15px;
  border: 1px solid $tableBorder;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.title_style {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $tableBorder;
  text-decoration-line: underline;
  text-align: left;
}
.title_style {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $tableBorder;
  text-decoration-line: underline;
  text-align: left;
}

.btn-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  padding: 0px 5px 0px 5px;
}

.btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  margin-right: 25px;
}

.image_style {
  border: 7px solid $paginateText;
  box-shadow: 0px 6.5045px 4.8836px 1px rgb(61 77 135 / 10%);
}

.tab-text {
  color: #473068;
  text-transform: none;
  opacity: 0.8;
}
.tab-text-active {
  font-weight: bolder;
  opacity: 1;
}

.name_style {
  color: $tableBorder;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 33px;
  margin: 12px;
}

.subtitle_data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $tableBorder;
}

.avatar {
  width: 100% !important;
  height: unset !important;
  max-width: 150px;
  aspect-ratio: 1/1;
}
.upload-new {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
}

.close-btn-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 25px;
}

.filter-container {
  padding-top: 20px;
  padding-bottom: 10px;
}

.form-label-style {
  color: $tableBorder;
  opacity: 0.7;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.close-btn {
  color: $tableBorder !important;
  background-color: $paginateText;
  border: 1px solid $tableBorder;
  border-radius: 3px;
  height: 40px;
}

.v-tab {
  text-decoration: none !important;
}

.v-tab:not(.v-tab--active):hover {
  background-color: #a89caa;
  cursor: pointer;
}

.v-tab:is(.v-tab--active):hover {
  background-color: transparent;
  cursor: pointer;
}

.v-file-input::v-deep .v-label {
  color: #473068;
}

.v-autocomplete::v-deep .v-label {
  color: #473068;
}

.save-btn {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
}

.header-assignement-container {
  vertical-align: middle;
  align-items: center;
  padding: 0px 16px;
}

.add-img {
  margin: -5px;
  background: $paginateText;
  width: 25px;
  height: 25px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0.5px solid #442d64;
}

.custom-list {
  border-collapse: collapse;
  width: 100%;
}

.custom-list .table-row {
  border-bottom: 1px solid #442d64;
  padding: 10px 0;
}

.custom-list .table-cell {
  padding: 5px;
}

.verify-btn-container {
  padding: 10px 0;
}

.add-img:hover {
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.add-img-icon {
  font-size: 15px;
  vertical-align: unset;
}

.verify-btn-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.verify-btn {
  margin-left: 10px;
}

.add-img {
  margin: -5px;
  background: $paginateText;
  width: 25px;
  height: 25px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0.5px solid #442d64;
}

.add-img:hover {
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.add-img-icon {
  font-size: 15px;
  vertical-align: unset;
}

.warning-message-container {
  margin-bottom: 10px;
  background: rgba(234, 122, 102, 0.1);
  border: 0.5px solid #ea7a66;
  border-radius: 3px;
  text-align: left;
}

.inactive-tab {
  color: #473068 !important;
  opacity: 0.8;
}

.warning-message-title {
  text-align: left;
  margin: 15px 0px 10px 10px;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ea7a66;
}

.v-autocomplete__content.v-menu__content .v-select-list {
  background-color: #f3edf7;
}

.country-selection-item {
  color: #442d65;
}

.tiles-container {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}
.tile-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
